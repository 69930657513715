.Settings{
    display: grid;
    grid-template-columns: 40% 60%;
    padding-bottom: 1rem;
}

.settings-profile-info{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
}

.settings-profile-info > h6{
    text-align: left;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    color: #b4b4b5;
    width: 100%;
}

.settings-profile-image{
    display: flex;
    align-items: center;
    justify-content: center;
    
    border: 13px solid #fff;
    filter: drop-shadow(0 1px 1px #656fb2);
    background-color: #fff;
    border-radius: 50%;

    position: relative; 
    width: 11rem; 
    height: 11rem; 
    overflow: hidden; 
    border-radius: 50%;
}


.settings-profile-image > img{
    display: inline;
    margin: 0 auto;
    width: 100%; 
    height: auto;
}

.settings-change-profile-image{
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings-change-profile-image > img{
    height: auto;
    width: auto;
    max-width: 2rem;
    max-height: 2rem;
    border-radius: 50%;
    filter: drop-shadow(0 1px 1px #656fb2);
}

.settings-profile-info-container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:1rem;
    width: 100%;
}

.settings-profile-biography{
    border: 3px solid #fff;
    background-color: #fff;
    filter: drop-shadow(0 1.5px 1.5px #656fb2);
    border-radius: 0.5rem;
    width: 90%;
    padding: 0.3rem;
    gap: 0.3rem;
}

.settings-profile-biography > h5{
    text-align: left;
    color: #656fb2;
    font-size: 12px;
    font-weight: bold;
}

.settings-profile-birthday{
    border: none;
    font-size: 12px;
    font-family: Poppins;
    color: #b4b4b5;
    background: transparent;
    outline: none;
    width: 100%;
}

.settings-profile-textarea{
    border: none;
    font-size: 12px;
    font-family: Poppins;
    color: #b4b4b5;
    background: transparent;
    outline: none;
    resize: none;
    overflow: scroll;
    width: 100%;
    height: 8vh;
}

.settings-settings-area{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.settings-list-item{
    width: 95%;
}

.settings-list{
    list-style: none;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.settings-settings-area > h5{
    text-align: left;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    color: #b4b4b5;
    width: 100%; 
}

