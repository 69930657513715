.PostShare{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;

    gap: 4rem;
    padding-bottom: 1rem;
}

.close-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.close-button > img{
    border-radius: 50%;
    max-height: 2rem;
    max-width: 2rem;
}

.close-button > img:hover{
    cursor: pointer;
}

.post-create-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.header-post-create > h6{
    padding-left: 1rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    font-size: 13px;
    text-align: left;
    color: #b4b4b5;
}

.post-photo-area{
    display: flex;
    flex-direction: column;

    width: 90%;
    height: 20vh;

    justify-content: center;
    align-items: center;
    align-content: center;

    background-color: #fff;
    border-radius: 0.5rem;
    filter: drop-shadow(0px 2px 4px #656fb2);
    gap: 2rem;
}

.header-post-create{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.photo-image{
    height: 3rem;
    width: 3rem;
    filter: drop-shadow(0px 2px 4px #656fb2);
    cursor: pointer;
}

.post-photo-area > p{
    font-size: 12px;
    color: #b4b4b5;
    font-weight: normal;
    text-align: center;
}

.post-desc-area{
    background-color: #fff;
    border-radius: 0.5rem;
    filter: drop-shadow(0px 2px 2px #656fb2);
    padding: 1rem;
    height: 10vh;
    width: 90%;
}

.post-location-area{
    background-color: #fff;
    border-radius: 0.5rem;
    filter: drop-shadow(0px 2px 2px #656fb2);
    padding: 1rem;
    width: 90%;
}

.post-desc-area > h6{
    font-size: 10px;
    color: #656fb2;
    font-weight: bold;
    text-align: left;
}

.post-location-area > h6{
    font-size: 10px;
    color: #656fb2;
    font-weight: bold;
    text-align: left;
}

.post-desc-area > textarea{
    background-color: transparent;
    border: none;
    outline: none;
    color: #b4b4b5;
    overflow: visible;
    resize: none;
}

.post-location-area > input{
    background-color: transparent;
    border: none;
    outline: none;
    color: #b4b4b5;
}

.previewImage{
    display: inline-block;
}

.close-btn-preview::before{
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.close-btn-preview{
    position: absolute;  
}

.close-btn-preview:hover{
    cursor: pointer;
}

.previewImage > img{
    display: block;
    max-width: 100%;
    max-height: 100%;

    border-radius: 0.5rem;
    overflow: hidden;
    object-fit: scale-down;
    border: 1px solid #fff;
}

.post-share-btn{
    width: 90%;
    height: 3rem;
}