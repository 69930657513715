.ModalDiscount{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    transform: matrix(1,0,0,1,0,0);
    align-items: center;
    justify-content: center;
}

.modal-discount-photo{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    height: auto;
    width: auto;

    max-height: 100%;
    max-width: 100%;
    
    border-radius: 0.5rem;
}

.modal-discount-photo>img{
    display: block;

    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    
    object-fit: scale-down;
    border-radius: 0.5rem;
    border: 1px solid #fff;
    
}

.discount-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.discount-username{
    text-align: left;
    color: #656fb2;
    font-weight: normal;
    font-size: 14px;
}