.UpperPanel{
    position: relative;
    display: grid;
    grid-template-columns: 30% calc(40% - 0.2rem) 30%;
    gap: 0.1rem;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-image: url(../../img/upper_panel_background.png);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 12vh;
    width: 100%;

}

.geyik-logo-banner{
    position: relative;
    width: 100%;
    height: 100%;
    left: -1rem;
    top: -0.2rem;
}

.UpperPanel-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 1rem;
    width: 100%;
}

.UpperPanel-welcome{
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-box{
    border: 1rem solid #f5f5f5;
    background-color: #f5f5f5;
    border-radius: 3rem;
    overflow: visible;
    padding-left: 1rem;
    padding-right: 1rem;
    color: rgba(180,180,181,1);
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
}

.welcome-box > b{
    font-style: normal;
    font-weight: bold;
}

.white-background{
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: calc(45% - 0.1rem) 55%;
}

.white-side{
    background-color: #fff;
    width: 100%;
    height: 100%;
}