.BusinessProfileSide{
    display:grid;
    position: relative;
    gap: 0.1rem;
    grid-template-columns: 55% calc(45% - 0.1rem);
    background-color: #dddde641;
    height: 100%;
}

.business-profile-side{
    padding-bottom: 1rem;
}
