.SingleAdvertisement{
    position: relative;
    width: 100vw;
}

.SingleAdvertisement-upper{
    position: relative;
}

.SingleAdvertisement-lower{
    position: relative;
    display: grid;
    grid-template-columns: 30% calc(40% - 0.2rem) 30% ;
    gap: 0.1rem;
    min-height: 88vh;
    height: auto;
}