.MarketplaceShare{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;
    gap: 2rem;
}

.marketplace-post-create-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

}

.marketplace-post-image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #fff;
    filter: drop-shadow(0px 2px 4px #656fb2);
    width: 85%;
    height: 20vh;
    border-radius: 0.5rem;
    padding: 1rem;
    gap: 1rem;
}

.marketplace-desc-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #fff;
    filter: drop-shadow(0px 2px 4px #656fb2);
    width: 85%;
    border-radius: 0.5rem;
    padding: 1rem;
    gap: 1rem;
}

.marketplace-desc-area > textarea{
    background-color: transparent;
    border: none;
    outline: none;
    color: #b4b4b5;
    overflow: visible;
    resize: none;
    width: auto;
    height: 6vh;
}

.marketplace-desc-area > h6{
    width: 100%;
    text-align: left;
}

.marketplace-price-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #fff;
    filter: drop-shadow(0px 2px 4px #656fb2);
    width: 85%;
    border-radius: 0.5rem;
    padding: 1rem;
    gap: 1rem;
}

.marketplace-price-area > h6{
    width: 100%;
    text-align: left;
}

.marketplace-price-area > input{
    background-color: transparent;
    border: none;
    outline: none;
    color: #b4b4b5;
    text-align: center;
}

.marketplace-checkRemove{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #fff;
    filter: drop-shadow(0px 2px 4px #656fb2);
    width: 85%;
    border-radius: 0.5rem;
    padding: 1rem;
    gap: 1rem;
}