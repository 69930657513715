.ChatUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.white-circle-chats{
    border-radius: 50%;
    border: 0.5rem solid #fff;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    overflow: hidden;
    max-height: 4rem;
    max-width: 4rem;
}

.white-circle-chats > img{
    height: auto;
    width: 100%;
    cursor: pointer;
}