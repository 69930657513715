.MessagePanelUsers{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    gap:1rem;
}

.user-searchbar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.chat-user-list{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.message-panel-users-upper{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}

.message-panel-users-upper > h6{
    font-size: 12px;
    color: #b4b4b5;
    text-align: left;
}