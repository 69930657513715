

.register-main-panel{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.personal-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.register-page-one{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.register-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    gap: 1rem;
}

.register-input-area{
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: rgba(255,255,255,1);
    filter: drop-shadow(0px 1px 2px #656fb2);
    border-radius: 5rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 0.7rem 0.4rem 0.7rem;
}

.register-login-btn{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0.3rem;
}

.register-textarea{
    resize: none;
    overflow: scroll;
    width: 320%;
    background:transparent;
    border: none;
    outline: none;
}

.register-description{
    text-align: center;
    color: #656fb2;
    font-weight: normal;
    font-style: normal;
}

.register-upper-desc{
    padding-top: 1rem;
    font-weight: bold;
    font-size: 15px;
}

.register-profile-desc{
    font-weight: bold;
    font-size: 15px;
}

.single-row{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.tiny-input{
    width: 100%;
}
