.ModalPost{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    transform: matrix(1,0,0,1,0,0);
    align-items: center;
    justify-content: center;
}

.modal-post-photo{
    position: relative;
    display: flex;

    height: auto;
    width: auto;

    max-height: 100%;
    max-width: 100%;

    justify-content: center;
    align-items: flex-end;
    
}

.modal-dated{
    position: absolute;   
    height: 20%;    
    width: 75%;
    text-align: right;
    overflow: hidden;
    color: #fff;
    font-size: 15px;
    text-shadow: -0.25px 0 black, 0 0.25px black, 0.25px 0 black, 0 -0.25px black;
}

.modal-dated:before{
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.modal-post-photo>img{
    display: block;

    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;

    border-radius: 0.5rem;
    overflow: hidden;
    object-fit: scale-down;
    border: 1px solid #fff;
    
}

.modal-info-area{
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
}

.modal-detail{
    display: flex;
    flex-direction: column;
}

.modal-username{
    overflow: hidden;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(101,111,178,1);
}

.modal-description{
    overflow: hidden;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(180,180,181,1);
}

.modal-postReact{
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    gap: 1.5rem;
    
}

.modal-postReact :nth-child(1){
    width: 1.5rem;
    height: 1.8rem;
}

.modal-postReact :nth-child(2){
    width: 1.5rem;
    height: 1.5rem;
}

.modal-writeComment{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap : 1rem;
    background-color: #d8c0c0;
    border-radius: 1.5rem;
    margin: 0.5rem, 0.5rem 0.5rem 0.5rem;
}