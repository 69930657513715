.business-register-description{
    text-align: center;
    color: #ff5e23;
    font-weight: normal;
    font-style: normal;
}

.business-register-input-area{
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: rgba(255,255,255,1);
    filter: drop-shadow(0px 1px 1px #ff5e23);
    border-radius: 5rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 0.7rem 0.4rem 0.7rem;
}