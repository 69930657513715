.VerifyEmail{
    position: relative;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    height: 100vh;
    padding: 1rem;
}

.verify-main-panel{
    position: relative;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.verify-main-panel > h1{
    text-align: center;
    color: #656fb2;
}