.Post{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    background-color: #fff;;
    border-radius: 1rem;
    gap: 0.5rem;
    transform: matrix(1,0,0,1,0,0);
}

.post-photo{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    height: 100%;
    width: 100%;
    max-height: 44rem;
    max-width: 44rem;
    overflow: hidden;
    border-radius: 0.5rem;
}

.dated{
    position: absolute;   
    height: 20%;    
    width: 75%;
    text-align: right;
    overflow: hidden;
    color: #fff;
    font-size: 15px;
    text-shadow: -0.25px 0 black, 0 0.25px black, 0.25px 0 black, 0 -0.25px black;
}

.dated:before{
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.post-photo>img{
    display: block;
    
    width: auto;
    height: auto;
    border-radius: 0.5rem;
    
    overflow: hidden;
    object-fit: scale-down;
    border: 1px solid #fff;
    
}

.info-area{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.detail{
    display: flex;
    flex-direction: column;
}

.username{
    overflow: hidden;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(101,111,178,1);
}

.description{
    overflow: hidden;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(180,180,181,1);
}

.postReact{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
}

.postReact :nth-child(1){
    width: 1.5rem;
    height: 1.8rem;
}

.postReact :nth-child(2){
    width: 1.5rem;
    height: 1.5rem;
}

.shareBtn {
    width: 1.5rem;
    height: 1.5rem;
}


.writeComment{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap : 1rem;
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    margin: 0.5rem, 0.5rem 0.5rem 0.5rem;
}

.comments{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    height: 5rem;
    width: 100%;
    overflow: scroll;
}

.comments-inside{
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: 0.5rem;
    flex-wrap: nowrap;
    height: 100%;
}

.comment-submit{
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.comment-user-title{
    color: #646fb2;
    font-size: 17px;
    font-weight: bold;
}