.Messages{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    height: 28vh;
    overflow: scroll;
    padding: 0.1rem;
}
 .feed{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
 }

 .position {
   width: 100%;
 }

 .right {
   display: flex;
   flex-direction: column;
   width: 100%;
   justify-content: center;
   align-items: flex-end;
}

.left {
   display: flex;
   flex-direction: column;
   width: 100%;
   justify-content: center;
   align-items: flex-start;
}