.CreateActivity{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: #fff;
    gap: 1rem;
    padding-bottom: 1rem;
}

.activity-preview-container{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.header-activity-create{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.header-activity-create > h6 {
    padding: 0;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #b4b4b5;

}

.header-activity-preview{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

}

.create-activity-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    

}

.activity-types{
    display: flex;
    height: 16rem;
    width: 100%;
    overflow: scroll;
    
}

.activity-types-list{
    display: flex;
    flex-direction: row;
    list-style-type: none;
    gap: 0.5rem;
    flex-wrap: nowrap;
    height: 100%;
}

.activity-type{
    color: #656fb2;
    width: 11rem;
}



.activity-add-media{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    min-height: 8rem;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 0.5rem;
    filter: drop-shadow(0px 2px 4px #656fb2);
    padding: 1rem;
}

.activity-add-title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 1rem;
    filter: drop-shadow(0px 2px 4px #656fb2);
    padding: 1rem;
}

.activity-add-title > input{
    border: none;
    background: transparent;
    outline: none;
}

.activity-add-privacy{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 1rem;
    filter: drop-shadow(0px 2px 4px #656fb2);
    padding: 1rem;
}

.form-photo-image:hover{
    cursor: pointer;
}