.ProfileActivities{
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%; 
    gap: 1rem;
    padding: 1rem;
}

.ProfileActivities-grid{
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%; 
    gap: 1rem;
    padding: 1rem;
}


.asdsdasdsaddada{
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;

    display: flex;
    flex-direction: column;
}