.ActivityTinyCard{
    width: 100%;
    height: calc(width);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;
    background-color: #fff;
    border-radius: 0.3rem;
    border: 1px solid #fff;
    filter: drop-shadow(0px 2px 4px #656fb2);
    min-height: 15vh;
}

.activity-post-image-tn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.activity-post-image-tn > img{
    width: 100%;
    height: 100%;
    border-radius: 0.3rem;
}

.activity-tn-info{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    height: 100%;
    backdrop-filter: blur(50px);
    gap: 0.3rem;
}

.activity-tn-inside{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.activity-tn-inside > h4{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}

.activity-tn-inside > h6{
    text-align: left;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
}

.activity-tn-inside > p{
    text-align: left;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
}

.activity-tn-plus{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 90%;
}

.activity-tn-plus > img{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
}
