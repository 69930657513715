.ChatUserWide{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background-color: #fff;
    border-radius: 2rem;
    filter: drop-shadow(0px 2px 2px #b4b4b5);
}

.ChatUserWide:hover{
    cursor: pointer;
}

.chat-user-wide-info{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.chat-user-wide-name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 10px;
    font-weight: normal;
}

.chat-user-wide-name > span::nth-of-type(1) {
    font-weight: bold;
    color: #656fb2;
}


.chat-user-wide-name > span::nth-of-type(1) {
    font-weight: normal;
    color: #b4b4b5;
}

.chat-user-wide-pp{
    width: 2.7rem;
    height: 2.7rem;
    border-radius:50%;
}

.chat-user-wide-chat-logo{
    padding-right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
}