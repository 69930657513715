.Discount{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    background-color: #fff;;
    border-radius: 1rem;
    gap: 0.5rem;
    transform: matrix(1,0,0,1,0,0);
}

.discount-photo{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    height: 100%;
    width: 100%;
    max-height: 44rem;
    max-width: 44rem;
    overflow: hidden;
    border-radius: 0.5rem;
}

.discount-photo>img{
    display: block;
    
    width: auto;
    height: auto;
    border-radius: 0.5rem;

    overflow: hidden;
    object-fit: scale-down;
    border: 1px solid #fff;
    
}

.discount-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.discount-username{
    text-align: left;
    color: #656fb2;
    font-weight: normal;
    font-size: 14px;
}