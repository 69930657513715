.Home{
    position: relative;
    width: 100vw;
}

.Home-upper{
    position: relative;
}

.Home-lower{
    position: relative;
    display: grid;
    grid-template-columns: 30% calc(40% - 0.2rem) 30% ;
    gap: 0.1rem;
    min-height: 88vh;
    height: auto;
}

.info-text{
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(180,180,181,1);
}

.info-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    padding-left: 1rem;
    width: 90%;
}