.ModalActivity{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    transform: matrix(1,0,0,1,0,0);
    align-items: center;
    justify-content: center;

}

.modal-activity-image-area{
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: auto;
    width: auto;

    max-height: 100%;
    max-width: 100%;

    border-radius: 0.5rem;
}

.modal-activity-image-area > img{
    display: block;
    
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;

    border-radius: 0.5rem;
    object-fit: scale-down;
    border: 1px solid #fff;
}

.modal-activity-info-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    padding-left: 3rem;
}

.modal-activity-info-container > h4{
    font-weight: bold;
    text-align: left;
    font-size: 24px;
    text-decoration: none;
    color: #fff;
}

.modal-activity-info-description{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.modal-activity-info-description > h6{
    font-weight: normal;
    text-align: left;
    font-size: 16px;
    text-decoration: none;
    color: #fff;
}

.modal-activity-info-description > h4{
    font-weight: normal;
    text-align: left;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
}

.modal-activity-info-description > p{
    font-weight: normal;
    text-align: left;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
}


.modal-activity-info{
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    justify-content: space-between;
    align-items: flex-start;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    width: 40%;
    height: 100%;
    backdrop-filter: blur(50px);
    gap: 0.3rem;
}

.modal-activity-username{
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    text-decoration: none;
    color: #656fb2;
}

.modal-activity-image-plus{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.modal-activity-plus{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: rgba(137, 246, 145,0.4);
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    width: 80%;
    
}

.modal-activity-join-button{
    font-weight: normal;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    color: #fff;
}

.modal-activity-plus > img{
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    cursor: pointer;
}

.modal-comments{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 90%;
    max-height: 5rem;
    height: auto;
    overflow: scroll;
}

.modal-activity-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}