.MyMarketplace{
    display:flex;
    flex-direction:column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    gap: 1rem;
}

.marketplace-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
}

.marketplace-header > h6{
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    color: #b4b4b5;
}

.marketplace-ads-container{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    gap: 1.2rem;
}