.ChatBox{
    position: fixed;
    bottom: 41vh;
    right: 29.5vw;
}

.ChatBox-closed{
    position: fixed;
    bottom: 7vh;
    right: 29.5vw;
}

.absolute-part{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content :flex-start;
    gap: 0.1rem;
    width: 20vw;
    background-color : #f5f5f5;
    border: 1px solid #f5f5f5;
    border-top-left-radius : 0.25rem;
    border-top-right-radius : 0.25rem;
    border: 1px solid #b4b4b5;
}

.close-button{
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
}

.chat-logos{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

}

.online-bar{
    height: 7vh;
}

.online-box{
    
    width: 1.3rem;
    height: 6.9vh;
    background-color : #95f16a;
    border-top-left-radius: 0.25rem;
}

.upper-border {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width : 100%;
    height: 100%;

}

.infoArea{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width : 100%;
    background-color : rgba(32, 32, 32, 1);
    border-top-right-radius: 0.25rem;
    padding : 0.5rem;
}

.chat-info > h6{
    text-align: left;
    font-weight: bold;
    color: #fff;
    font-size: 17px;
}

.chat-info > span {
    text-align: left;
    font-weight: normal;
    color: rgb(209, 201, 201);
    font-size: 10px;
}

.SendArea > input{
    background-color: transparent;
    border: none;
    outline: none;
    color: #000;
    width : 100%;
}

.SendArea{
    display: flex;
    flex-direction: row;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    background-color: #fff;
    width : 100%;
    height : 5vh;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
}

.white-circle-chatbox{
    border-radius: 50%;

}

.white-circle-chatbox > img{
    width: 2rem;
    height : 2rem;
}

.TextArea{
    border:none;
    outline: none;
    background-color: var(--inputColor);
    border-radius: 8px;
    padding: 10px;
    flex:1;
}

.chatbox-messages{
    width: 90%;
}