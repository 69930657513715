.SponsoredAds{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 1rem;
    border: 0.2rem solid #fff;
}

.sponsored-ads{
    width: 100%;
    height: 100%;
    display: block;
    height: auto;
    border-radius: 0.5rem;
    overflow: hidden;
    object-fit: scale-down;
    border: 1px solid #fff;
}