.FormShare{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    width: 100%;
    height: 100%;
    gap: 1rem;
}

.forum-close-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 90%;
}

.forum-close-button > img {
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
}

.forum-create-menu{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.forum-write-area > input{
    background-color: transparent;
    border: none;
    outline: none;
    width: 90%;
}

.forum-write-area > textarea{
    background-color: transparent;
    border: none;
    outline: none;
    width: 90%;
}

.forum-write-area{
    display: flex;
    flex-direction: column;
    width: 85%;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1rem solid #fff;
    background-color: #fff;
    filter: drop-shadow(0px 2px 4px #656fb2);
}

.upper-title{
    font-size: 18px;
    color: #656fb2;
    font-weight: bold;
    font-style: normal;
    text-align: left;
}

.upper-title::placeholder{
    font-size: 18px;
    color: #656fb2;
    font-weight: normal;
    font-style: normal;
    text-align: left;
}

.lower-title{
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    text-align: left;
}

.forum-desc{
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    overflow: visible;
    resize: none;
    height: 10vh;
}

.forum-media-area{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 3px solid #fff;
    filter: drop-shadow(0px 2px 4px #656fb2);
    width: 85%;
    border-radius: 0.5rem;
    gap: 1rem;
}

.form-photo-image{
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    filter: drop-shadow(0px 2px 4px #656fb2);
}

.forum-media-area > p{
    font-size: 14px;
    text-align: center;
}