.ProfileDetails{
    display: grid;
    grid-template-columns: 35% 65%;
}

.profile-profile-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.profile-profile-image{
    border: 13px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    filter: drop-shadow(0 1px px #656fb2);
    width: 100%;
    height: 100%;

    max-width: 11rem;
    max-height: 11rem;
    overflow: hidden;
    position: relative;
}

.profile-profile-image > img{
    display: inline;
    margin: 0 auto;
    width: 100%;
    height: auto;
}

.profile-user-statistics{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}

.profile-user-statistic{
    text-align: center;
    color: #656fb2;
    font-size: 16px;
    font-weight: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 0.3rem;
}

.profile-user-statistic > b{
    font-size: 13px;
    font-weight: bold;
}

.profile-profile-desc{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.profile-profile-description{
    width: 100%;
    height: 5vh;
    overflow: scroll;
}

.profile-profile-description > p{
    width: 100%;
    height: 100%;
    overflow: scroll;
    text-align: center;
    font-size: 12px;
    color: #b4b4b5;
    font-style: normal;
    font-weight: normal;
}

.profile-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
    width: 100%;
}

.profile-btn{
    width: 90%;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.profile-posts{
    width: 100%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 1rem;
    justify-content: flex-start;
}

.profile-post-photo{
    height: 13%;
}

.profile-posts-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
}

.profile-posts-container > h6{
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    color: #b4b4b5;
}

.button-closed{
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 100ms ease-out;
    color: #b4b4b5;
    border: 2px solid #b4b4b5;
    background: transparent;
    border-radius: 5rem;
}

.button-closed:hover{
    cursor: pointer;
    color:white;
    border: none;
    background: var(--buttonBg);
}
  
.button-closed:disabled{
    background:silver;
    pointer-events: none;
}

.profile-user-bio{
    font-size: 12px;
    text-align: center;
    color: #000;
    text-decoration: none;
}

.profile-user-following-true{
    width: 50%;
    height: 2rem;
}

.profile-user-following-false{
    width: 50%;
    height: 2rem;
}

.profile-connect-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.profile-interaction-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #fff;
    filter: drop-shadow(0px 2px 4px #656fb2);
    width: 2rem;
    height: 2rem;
}

.profile-interaction-button:hover{
    cursor: pointer;
}

.profile-interaction-button-image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;
    filter: drop-shadow(0px 2px 4px #656fb2);
    width: 2.3rem;
    height: 2.3rem;
}

.profile-interaction-button-image:hover{
    cursor: pointer;
}

#profile-other-new-chat{
    width: 2rem;
    height: 2rem;
}

.profile-bio{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile-activities-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

.profile-activities-container > h6{
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    color: #b4b4b5;
}

.photo-32x32{
    width: 32px;
    height: 32px;
}

.profile-activities{
    width: 100%;
    grid-gap: 1rem;
    justify-content: flex-start;
    
}