.MarketplaceAds{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: calc(width);
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 0.5rem;
    filter: drop-shadow(0px 2px 4px #656fb2);
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    gap: 1rem;
}

.marketplace-ad-image > img {
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
}

.marketplace-ad-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.marketplace-ad-info > h6{
    font-weight: bold;
    font-size: 14px;
    color: #b4b4b5;
}

.marketplace-ad-title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.marketplace-ad-title > h5 {
    font-weight: bold;
    font-size: 10px;
    color: #656fb2;
}

.marketplace-ad-title > h6 {
    font-weight: bold;
    font-size: 10px;
    color: #b4b4b5;
}

.link-center{

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}