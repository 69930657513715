.socialNavbar{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.s-navbar-menu{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap : 0.5rem;
}

.s-nb-menu-item{
    position: relative;
    list-style-type:none;
    object-fit: cover;
    overflow: visible;
    width: 90%;
    filter: drop-shadow(0px 3px 6px rgba(128, 130, 208, 0.2));
}

.s-nb-menu-item > a{
    text-decoration: none;
}