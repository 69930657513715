#ik-picture{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    bottom: -13rem;
    right: -13rem;
    opacity: 0.7;
    
}

#ik-picture > img {
    height: 40%;
    width: 40%;
}