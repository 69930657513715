.ForumPost{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    background-color: #fff;;
    border-radius: 1rem;
    gap: 0.5rem;
    transform: matrix(1,0,0,1,0,0);
}

.title-desc-photo{
    display: grid;
    grid-template-columns: 50% 50%;
}

.forum-post-text-area{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:0.5rem;
}

.forum-post-text-area > h1 {
    font-size: 32px;
    font-weight: normal;
    text-align: left;
    color: #656fb2
}


.forum-post-text-area > h4 {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    color: #b4b4b5;
}


.forum-post-text-area > p{
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #b4b4b5;
}

.forum-post-photo-area{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.forum-post-photo-area > img{
    display: block;
    max-width: 100%;
    max-height: 100%;

    width: auto;
    height: auto;
    border-radius: 0.5rem;

    border-radius: 0.5rem;
    overflow: hidden;
    object-fit: scale-down;
    border: 1px solid #fff;
}

.forum-username{
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #656fb2
}