.Notifications{
    width: 100%;
    height: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    background-color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
}

.Notifications-Info{
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
}

.Notifications-Info > h6:nth-child(1) {
    color: #b4b4b5;
    font-style: normal;
    font-weight: normal;
    text-align: left;
}

.Notifications-Info > h6:nth-child(2){
    color: #656fb2;
    font-style: normal;
    font-weight: normal;
    text-align: right;
    cursor: pointer;
}

.NotificationsArea{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 1rem;
}

.Noti{
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    size: 90%;
}