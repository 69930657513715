.ResetPassword{
    position: relative;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    height: 100vh;
    padding: 1rem;
}

.reset-password-main-panel{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.reset-password-info-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.reset-password-button{
    width: 100%;
    z-index: 10;
}