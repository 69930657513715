.Setting{
    width:100%;
}

.setting-container{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    border: 1px solid #fff;
    filter: drop-shadow(0px 1px 2px #656fb2);
    border-radius: 0.5rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
}

.setting-container > img {
    height: 20%;
    width: 20%;
    border-radius: 1rem;
}

.setting-title-desc{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.setting-title-desc >h5{
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    text-decoration: none;
    color: #656fb2;
}

.setting-title-desc >h3{
    font-weight: normal;;
    font-size: 10px;
    text-align: left;
    text-decoration: none;
    color: #b4b4b5;
}

.setting-title-desc >h6{
    font-weight: normal;;
    font-size: 10px;
    text-align: left;
    text-decoration: none;
    color: #b4b4b5;
}