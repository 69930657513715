.MarketplacePost{
    width:100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #fff;;
    border-radius: 1rem;
    gap: 0.5rem;
}

.marketplace-post-image-area{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    height: 100%;
    width: 100%;
    max-height: 44rem;
    max-width: 44rem;
    overflow: hidden;
    border-radius: 0.5rem;
}

.marketplace-post-image-area > img{
    display: block;
    
    width: auto;
    height: auto;
    border-radius: 0.5rem;
    
    overflow: hidden;
    object-fit: scale-down;
    border: 1px solid #fff;
}

.postReact > b{
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    text-align: left;
    color: #656fb2;
}

.marketplace-post-desc-area{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.marketplace-post-desc-area-inner > h2{
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #656fb2;
}

.marketplace-inner-h2{
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #656fb2;
}

.marketplace-post-desc-area-inner > h5{
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #b4b4b5;
}