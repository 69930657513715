.Auth{
    position: relative;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    height: 100vh;
    padding: 1rem;
}
.middle {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.form{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.main-panel{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
}

#upper-picture{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: -10rem;
    height: 25%;
    opacity: 0.1;
}

#upper-picture > img{
    width:70%;
}

.Logo{
    width: 40%;
}

.quote{
    display: flex;
    position: relative;
	overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    color: rgba(101,111,178,1);
}

.to-hr-page{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: flex-end;
}

.hr-circle{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    background-color: rgb(146, 155, 155);
    border:1px solid rgb(146, 155, 155);  
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:1.8rem;
    height:1.8rem;
    text-decoration: none;
}
.hr-circle-p{
    color: rgba(101,111,178,1);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
}
.link{
    text-decoration: none;
}

.hr-circle-p:hover{
    color: red;
}

.logo-and-quote{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.auth-links{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:98%;
    gap: 2rem;
    position: relative;
}

.input-area{
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: rgba(255,255,255,1);
    filter: drop-shadow(0px 3px 6px rgba(128, 130, 208, 0.2));
    border-radius: 5rem;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    gap: 0.2rem;
    
}


.input-container > p{
    display: flex;
    font-size: 10px;
    color: rgba(101,111,178,1);  
}

.link-font{
    font-size: 10px;
    color: rgba(101,111,178,1);
    text-decoration: none;
}

.link-font:hover{
    color: red;
}

.input-container{
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 1.5rem;
}

.svg-img{
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
}

.svg-img:hover{
    cursor: pointer;
}

.emailInput{
    border:none;
    outline: none;
    width:320%;
    background: transparent;
    overflow: hidden;
}

.passwordInput{
    border:none;
    outline: none;
    width:300%;
    background: transparent;
    overflow: hidden;
}

.logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo > img{
    width: 15%;
}

.infoForm{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 100ms ease-out;
    color: var(--orange);
    border: 2px solid var(--orange);
    background: transparent;
    border-radius: 5rem;
  }
  
  .button:hover{
    cursor: pointer;
    color:white;
    border: none;
    background: var(--buttonBg);
  }
  
  .button:disabled{
    background:silver;
    pointer-events: none;
  }

  .infoButton{
    width: 100%;
    height: 3.2rem;
  }