.profileCard {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 0.2rem;
    overflow-x: clip;
    background-color: #fff;;
    width: 90%;
    height: 35vh;
    
    
}

.upper-image{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upper-image > img {
    width: 100%;
    height: 100%;
    
}

/* Profile Images */
.ProfileImages {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 15px solid #fff;
    background-color: #fff;
    filter: drop-shadow(0 0.5px 0.5px #656fb2);
    
    width: 100%;
    height: 100%;

    max-width: 10rem;
    max-height: 10rem;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
}

.ProfileImages>img {
    display: inline;
    margin: 0 auto;
    height: auto;
    width: 100%;
}

/* Profile Name */

.ProfileName{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.user-statistics{
    white-space: nowrap;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(101,111,178,1);
}

.ProfileName > span:nth-of-type(1){
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(101,111,178,1);
}

.ProfileName > span:nth-of-type(2){
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(180,180,181,1);
}

/* Follow status */
.following-details{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 3px solid #fff;
    padding: 0.5rem;
}

.followStatus{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.followStatus > hr {
    width:85%;
    border: 1px solid var(--hrColor);
}

.followStatus > div{
    display: flex;
    gap: 1rem;
    width: 80%;
    justify-content: space-around;
    align-items: center;
}

.follow{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: center;
    justify-content: center;
}

.follow > span:nth-of-type(1){
    font-weight: bold;
}

.follow > span:nth-of-type(2){
    color: var(--gray);
    font-size: 13px;
}

.vl{
    height: 150%;
    border-left: 2px solid var(--hrColor); 
}

#MyProfile{
    font-weight: bold;
    color: #8082d0;
    align-self: center;
    margin-bottom: 1rem;
    cursor: pointer;
}