.Notification{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background-color: #fff;
    border-radius: 2rem;
    filter: drop-shadow(0px 2px 2px #b4b4b5);
    padding-right: 1rem;
}
.notification-link{
    text-decoration: none;
    width: 100%;
    height: 100%;
}
.notification-colum{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
}

.notification-info-area{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.notification-username{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #1ea5ff;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.NotificationData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #b4b4b5;
    text-align: left;
}

.timesclale{
    display: flex;
    flex-direction: column;
    color: #000000;
    align-items: center;
    text-align: left;
}

.Notification-user-photo > img{
    width: 2.7rem;
    height: 2.7rem;
    border-radius:50%;
}

.activity-accept-buttons{
    width: 100%;
    display: flex;
    flex-direction : row;
    align-items: center;
    justify-content: space-between;
}

.activity-button{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}