:root {
  --yellow: #f5c32c;
  --orange: #8082d0;
  --black: #242d49;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgba(255, 255, 255, 0.64);
  --buttonBg: linear-gradient(98.63deg, #8082d0 0%, #8082d0 100%);
  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4CB256;
  --video: #4A4EB7;
  --location: #EF5757;
--shedule: #E1AE4A;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}


.App{
  overflow: hidden;
  color: var(--black);
  background: #f5f5f5;;
}

.mobile{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
}

.blur{
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius:50%;
  background: #a6ddf0;
  filter: blur(72px)

}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms ease-out;
  color: var(--orange);
  border: 2px solid var(--orange);
  background: transparent;
  border-radius: 5rem;
}

.button:hover{
  cursor: pointer;
  color:white;
  border: none;
  background: var(--buttonBg);
}

.button:disabled{
  background:silver;
  pointer-events: none;
}

::-webkit-scrollbar{
  display:none;
}

.infoForm{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.infoInput{
  border:none;
  outline: none;
  background-color: rgba(255,255,255,1);
  border-radius: 8px;
  padding: 20px;
  flex:1;
  border-radius: 2rem;
}

.infoForm>div{
  display: flex;
  gap: 1rem;
  width: 90%;
  width: 90%;
}

.infoButton{
  width: 6rem;
  height: 2rem;
  align-self: flex-end;
}