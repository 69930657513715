.Toolbar{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Toolbar > ul{
    position: relative;
    list-style-type:none;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.Toolbar > ul > li{
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}

.Toolbar >ul > li :hover{
    cursor: pointer;
}

.Toolbar >ul > li > a{
    text-decoration: none;
}

.Tool{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(101,111,178,1);
    gap: 1rem;
}

.Tool > b{
    text-decoration: none;
}

.white-circle{
    display: flex;
    justify-content: center;
    align-items: center;

    background-color : #fff;
    border-radius: 50%;
    border: 2px solid #fff;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:1.8rem;
    height:1.8rem;
}

.white-circle > img {
    color: #656fb2
}