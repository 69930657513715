.RightSide{
    display:grid;
    position: relative;
    gap: 0.1rem;
    grid-template-columns: calc(45% - 0.1rem) 55%;
    background-color: #dddde641;
}

.rightSide-left-panel{
    background-color: #f5f5f5;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;
}

.rightSide-right-panel{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-color: #dddde641;
}



.NavIcons{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.NavIcons>a>img{
    width: 1.5rem;
    height: 1.5rem;
}

.NavIcons>img{
    width: 1.5rem;
    height: 1.5rem;
}

.NavIcons>img:hover{
    cursor: pointer;
}

.r-button{
    height: 3rem;
    width: 80%;
    align-self: center;
}

.noti-chats{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.sponsored-ads-width{
    width: 90%;
    height: calc(width);
}