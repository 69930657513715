.ActivityType{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    
}

.ActivityType:hover{
    cursor: pointer;
}

.Activity-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 90%;
    background-color: #fff;
    filter: drop-shadow(0px 2px 4px #646fb2);
    border-radius: 1rem;
}

.Activity-container > img {
    border-radius: 50%;
    border: 2px solid #fff;
    width: 5rem;
    height: 5rem;
}