.LogoSearch{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: auto;
}

.geyik-logo{
  width: 25%;
}

.search-and-logo{
  display: flex;
  width: 100%;
}

.Search{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 1rem;
    padding-left: 1rem;
    align-items: center;
    background-color: #fff;
    border-radius: 2rem;
    outline: none;
    position: relative;
}

.search-and-logo>input{
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.s-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: linear-gradient(106.23deg,#656fb2 0%, #656fb2 100%);
    border-radius: 5px;
    padding: 4px;
    color: #fff;
}

.s-icon > a{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: linear-gradient(106.23deg,#656fb2 0%, #656fb2 100%);
  border-radius: 5px;
  padding: 4px;
  color: #fff;
}

.dropdown {
  position: absolute;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    border: none;
    z-index: 99;
    width: 40%;
  }
  
  .dropdown:empty {
    border: none;
  }
  
  .dropdown-row {
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
  }

  .search-logo{
    width: 2rem;
    height: 2rem;
  }