.MenuItem {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 0.5rem;
    border: 1rem solid #fff;
    width: 100%;
    height: 4.368979591836735vh;
    object-fit: cover;
    overflow: visible;
}

.MenuItem:hover{
    cursor: pointer;
}

.navText{
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(101,111,178,1);
}

.navLogo{
    color: rgba(101,111,178,1);
    height: auto;
    width: auto;
    max-height: 45px;
    max-width : 46px;

}