.chatsSearchbar{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 2rem;
    filter: drop-shadow(0px 1px 2px #656fb2);
    padding: 0.3em;
    gap: 0.5rem;
}

.new-chat-button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-chat-button > img {
    height: 2rem;
    width: 2rem;
}

.chat-search-bar-input > input {
    background: transparent;
    border: none;
    outline: none;
    color: #b4b4b5;
}