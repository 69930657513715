.InfoCard{
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1rem;
    border-radius: 1rem;
    width: 100%;
}

.infoHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoHead> div:hover{
    cursor: pointer;
}

.logout-button{
    width: 90%;
    padding: 0.7rem;
    justify-content: center;
    align-self: center;
}