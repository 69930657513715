.businessButton {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 100ms ease-out;
    color: #ff5e23;
    border: 2px solid #ff5e23;
    background: transparent;
    border-radius: 5rem;
  }
  
  .businessButton:hover{
    cursor: pointer;
    color:white;
    border: none;
    background: #ff5e23;
  }
  
  .businessButton:disabled{
    background:silver;
    pointer-events: none;
  }

  .businessQuote{
    display: flex;
    position: relative;
	overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    color: #ff5e23;
}

.business-input-container > p{
    display: flex;
    font-size: 10px;
    color: #ff5e23;  
}

.business-link-font{
    font-size: 10px;
    color: #ff5e23;
    text-decoration: none;
}

.business-link-font:hover{
    color: rgba(101,111,178,1);
}

.business-input-container{
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 1.5rem;
}