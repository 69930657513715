.Message{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    max-width: 15vw;
}

.MyMessage{
    border-radius: 1rem;
    padding: 0.5rem 1rem 0.5rem 1rem ;
    background-color: #656fb2; /* Red */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.MyMessage > p{
    font-size: 10px;
    color: #fff;
    font-style: normal;
    font-weight: bold;
}

.GuestMessage{
    border-radius: 1rem;
    padding: 0.5rem 1rem 0.5rem 1rem ;
    background-color: #fff; /* Red */
    color: #656fb2;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.GuestMessage > p{
    font-size: 10px;
    color: #656fb2;
    font-style: normal;
    font-weight: normal;
}

.p{
    position: center;
    justify-content: center;
    align-items: center;
}