.ProfileSide{
    display:grid;
    position: relative;
    gap: 0.1rem;
    grid-template-columns: 55% calc(45% - 0.1rem);
    background-color: #dddde641;
    padding-bottom: 1rem;
}

.left-main-panel{
    background-color: #f5f5f5;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.left-left-panel{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-color: #dddde641;
}

.profile-side-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-decoration: none;
}