.ProfileActivityCard{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    height: auto;
    
}

.ProfileActivityCard-detail{
    display: flex;
    width: 100%;
    height: 100%;
}

.activity-profile-tn-post{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
}

.activity-profile-tn-post > img{
    width: 100%;
    border-radius: 0.2rem;
}

.activity-profile-closed{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    max-width: 115px;
    max-height: 115px;
    overflow: hidden;
    border-radius: 0.6rem;
}

.activity-profile-closed > img{
    width: 100%;
    border-radius: 0.2rem;
}

.activity-profile-tn-info{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 50%;
    height: 100%;
    backdrop-filter: blur(8px);
    gap: 0.3rem;
}

.activity-profile-tn-plus{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.activity-profile-tn-plus > img{
    width: 2rem;
    height: 2rem;
}

.activity-profile-info-inside > h4{
    text-align: left;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
}

.activity-profile-info-inside > h6{
    text-align: left;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
}

.activity-profile-info-inside > p{
    text-align: left;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
}