.TinyPost{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: 115px;
    max-height: 115px;
    overflow: hidden;
    border-radius: 0.6rem;
}

.TinyPost > img{
    width: 100%;
    border-radius: 0.2rem;
}