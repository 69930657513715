.Share{
    width: 100%;
    height: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    background-color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
}

.share-person-area{
    width: 100%;
}

.share-person-area:hover{
    cursor: pointer;
}