.MenuContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    position: relative;
}

.menu-date-title{
    filter: drop-shadow(0px 2px 4px #656fb2);
    background-color: #fff;
    border-radius: 1rem;
    padding: 0.5rem;
    position: absolute;
    top: -1.3rem;
    z-index: 10;
    margin-left: 1rem;
    
}

.menu-date-title > b{
    text-align: left;
    font-size: 12px;
    font-weight: normal;
    color: #656fb2;
}

.menu-container{
    position: relative;
    background-color: #fff;
    border-radius: 1rem;
    filter: drop-shadow(0px 2px 4px #656fb2);
    width: 100%;
    padding: 1.4rem 1rem 1rem 1rem;

}

.menu-container > h6{
    font-size: 14px;
    color: #656fb2;
    text-align: left;
}

.menu-container > p{
    color: #b4b4b5;
    font-size: 10px;
}

.menu-container > img {
    position: absolute;
    top: 5px;;
    right: 5px;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}