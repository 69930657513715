.Chats{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  bottom: 6vh;
  right: 4vw;
  height: auto;
  transition: bottom 2s;
}

.Chats-close{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    bottom: 2vh;
    right: 4vw;  
    transition: bottom 2s;
  }

.chats-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    max-height: 20rem;
    width: 100%;
    overflow: scroll;
}

.chats-inner-ul{
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: 0.5rem;
    flex-wrap: nowrap;
    height: 100%;
}

.white-circle-chats{
    border-radius: 50%;
    border: 0.5rem solid #fff;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 2px 4px #b4b4b5)
}

.white-circle-chats > img{
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    cursor: pointer;
}


.create-chat{
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    filter : drop-shadow(0px 2px 4px #656fb2);
    padding: 1rem;
}

.create-chat > img{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
}