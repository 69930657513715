.FollowersCard{
    width: 100%;
    height: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    background-color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
}

.FollowersCard > h6{
    color: #b4b4b5;
    font-style: normal;
    font-weight: normal;
}

.follower{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background-color: #fff;
    border-radius: 2rem;
    filter: drop-shadow(0px 2px 2px #b4b4b5);
}

.followerImage{
    width: 2.7rem;
    height: 2.7rem;
    border-radius:50%;
}

.user-info{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 10px;
    font-weight: normal;
}

.name>span:nth-of-type(1) {
    font-weight: bold;
    color: #656fb2;
}

.name>span:nth-of-type(2) {
    font-weight: normal;
    color: #b4b4b5;
}

.fc-button{
    height: 2rem;
    padding-left: 20px;
    padding-right: 20px;
}

.UnfollowButton{
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 100ms ease-out;
    border-radius: 5rem;
    cursor: pointer;
    color:white;
    border: none;
    background: var(--buttonBg);
}

.FollowersCard>span{
    font-weight: bold;
    color: #8082d0;
    align-self: center;
    cursor: pointer;
}

.user-logo{
    padding-right: 1rem;
    height: 2rem;
    width: 3rem;
}

.FollowersCard-list{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
}