.Comment{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}

.comment-inside{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.comment-inside > b{
    color: #656fb2;
    font-size: 16px;
    font-weight: bold;
}

.comment-inside > p{
    color: #000;
    font-size: 12px;
    font-weight: normal;
}

.Comment > p{
    color: #b4b4b5;
    font-size: 12px;
    font-weight: normal;
}