.SearchBar{
    display: flex;
    width: 100%;
    height: 43.19px;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    align-content: center;
    background-color: #fff;
    border-radius: 2rem;
    filter: drop-shadow(0px 2px 2px #656fb2);
}

.search-area{
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 90%;
}

.search-area > input{
    text-align: left;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
}
