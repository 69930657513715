.CheckModal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.CheckModal > b{
    font-weight: bold;
    color: #656fb2;
    font-size: 24px;
}

.checkmodal-buttons{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}