.ProfileBio{
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: start;
    justify-content: center;
    width: 100%;
    padding-bottom: 1rem;
}

.settings-profile-hr{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
}

.settings-profile-hr > h6{
    text-align: left;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    color: #b4b4b5;
    width: 100%;
}