.Activity{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    background-color: #fff;;
    border-radius: 1rem;
    gap: 0.5rem;
    transform: matrix(1,0,0,1,0,0);
    filter:drop-shadow(0px 2px 4px #656fb2);
}

.activity-image-area{
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 100%;
    width: 100%;
    max-height: 44rem;
    max-width: 44rem;
    overflow: hidden;
    border-radius: 0.5rem;
}

.activity-image-area > img{
    display: block;

    width: auto;
    height: auto;
    border-radius: 0.5rem;

    object-fit: scale-down;
    border: 1px solid #fff;
}

.activity-info-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    padding-left: 1rem;
}

.activity-info-container > h4{
    font-weight: bold;
    text-align: left;
    font-size: 30px;
    text-decoration: none;
    color: #fff;
}

.activity-info-description{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;
}

.activity-info-description > h6{
    font-weight: medium;
    text-align: left;
    font-size: 20px;
    text-decoration: none;
    color: #fff;
}

.activity-info-description > h4{
    font-weight: medium;
    text-align: left;
    font-size: 18px;
    text-decoration: none;
    color: #fff;
}

.activity-info-description > p{
    font-weight: medium;
    text-align: left;
    font-size: 16px;
    text-decoration: none;
    color: #fff;
}


.activity-info{
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    justify-content: space-between;
    align-items: flex-start;

    width: 40%;
    height: 100%;
    backdrop-filter: blur(50px);
    gap: 0.3rem;
}

.activity-username{
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    text-decoration: none;
    color: #656fb2;
}

.activity-image-plus{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.activity-plus{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: rgba(137, 246, 145,0.4);
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    width: 80%;
    
}

.activity-join-button{
    font-weight: normal;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    color: #fff;
}

.activity-plus > img{
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    cursor: pointer;
}

