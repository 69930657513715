.edit-textarea{
    background-color: #fff;
    border: 2px solid #646fb2;
    overflow: scroll;

    resize: none;
}

.edit-input{
    background-color: #fff;
    border: 2px solid #646fb2;
    overflow: scroll;

    resize: none;
}